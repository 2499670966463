import React, { useCallback, useState } from "react";
import { useStyles } from "./OverallSubscriptionForm.style";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Typography from "../../../common/Typography/Typography";
import labels from "../../../config/labels";
import HorizontalDivider from "../../../common/HorizontalDivider/HorizontalDivider";
import TextField from "../../../common/TextField/TextField";
import Button from "../../../common/Button/Button";
import { subscribeTo } from "../../../services/subscriber";
import { isValidEmail } from "../../../utils/helper";
import TownPicker from "../../../components/TownPicker/TownPicker";
import { getDropdownChoices } from "../../../services/townService";
import FooterPolicyAndTermsText from "../../../components/Footer/SubscribeForm/FooterPolicyAndTermsText";

const ACTION = "townSignUp";

const OverallSubscriptionForm = ({ townsWithClusters }) => {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [token, setToken] = useState("");
  const [inputError, setInputError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [successMessage, setSuccessMessage] = useState();
  const [towns, setTowns] = useState([]);

  const handleReCaptchaVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  const ErrorMessage = () => {
    return (
      <Typography className={classes.error_message} level="medium_12_px" bold>
        {inputError ? labels.INPUT_ERROR : ""}
        {errorMessage ?? errorMessage}
      </Typography>
    );
  };

  const handleCommitAction = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const result = executeRecaptcha(ACTION);

    setToken(result);

    //Input validation
    if (!isValidEmail(subscriberEmail) || towns.length === 0) {
      if (!isValidEmail(subscriberEmail)) {
        setInputError(true);
      } else {
        setInputError(false);
      }
      if (towns.length === 0) {
        setErrorMessage(labels.SELECT_AT_LEAST_ONE_TOWN);
      } else {
        setErrorMessage();
      }
    } else if (
      subscriberEmail &&
      isValidEmail(subscriberEmail) &&
      towns.length > 0
    ) {
      setButtonDisabled(true);
      const multipleTowns = true;
      try {
        const subscribeResponse = await subscribeTo(
          "town",
          towns,
          subscriberEmail,
          token,
          "town",
          multipleTowns
        );

        if (subscribeResponse?.status === 200) {
          setSuccessMessage(
            labels.YOU_HAVE_SUCCESSFULLY_SIGNED_UP_MULTIPLE_TOWNS
          );
        } else {
          setInputError(true);
        }
      } catch (e) {
        setErrorMessage(labels.SIGNUP_ERROR);
      }
    }
  }, [executeRecaptcha, subscriberEmail, towns]);

  const handleTownsSelected = (selectedTowns) => {
    const townsSelected = selectedTowns.map((town) => {
      return townsWithClusters.find((t) => t.id === town.id).slug;
    });

    setTowns(townsSelected);
  };

  return (
    <div className={classes.container}>
      <Typography level={"h1"} centered className={classes.title}>
        {labels.SIGN_UP_FOR_FREE_E_NEWS}
      </Typography>
      <Typography
        level={"h3_sub_header"}
        centered
        className={classes.sub_title}
      >
        {labels.SIGN_UP_OVERALL}
      </Typography>
      <HorizontalDivider className={classes.divider} />
      {!successMessage && (
        <div className={classes.form_container}>
          <div className={classes.row}>
            <div className={classes.text_field}>
              <TextField
                value={subscriberEmail}
                defaultValue={subscriberEmail}
                onChange={(event) => setSubscriberEmail(event.target.value)}
                placeholder={labels.EMAIL_PLACEHOLDER}
                name="email"
                type="email"
                label={labels.EMAIL_ADDRESS}
                labelLevel={"medium_12_px"}
                error={inputError}
              />
            </div>
            <div className={classes.town_picker_container}>
              <Typography
                level="medium_12_px"
                color="gray"
                bold
                className={classes.picker_label}
              >
                {labels.CHOOSE_TAPINTO_SITES}
              </Typography>
              <TownPicker
                isTownPickerMobile={true}
                townsAsItems={getDropdownChoices(townsWithClusters, () => "")}
                isMultipleWithTags={true}
                setTagsArray={handleTownsSelected}
              />
            </div>
          </div>
          <div className={classes.centered}>
            <FooterPolicyAndTermsText textColor={"black"} />
          </div>
          <div className={classes.centered}>
            <Button
              level="simple_button"
              color="primary"
              variant="contained"
              onClick={handleCommitAction}
              className={classes.signup}
              id="newsletter-subscription-signup-overall"
              disabled={buttonDisabled}
            >
              <Typography level="tiny" color="white" bold>
                {labels.SIGN_UP}
              </Typography>
            </Button>
          </div>
        </div>
      )}
      {successMessage && (
        <Typography
          color="white"
          level="medium_13_px"
          className={classes.success_message}
        >
          {successMessage}
        </Typography>
      )}
      {(inputError || errorMessage) && (
        <div className={classes.centered}>
          <ErrorMessage />
        </div>
      )}
      <GoogleReCaptcha action={ACTION} onVerify={handleReCaptchaVerify} />
    </div>
  );
};

export default OverallSubscriptionForm;
